<template>
  <div>
    <feed-pet-stage />

    <dashboard-onboarding-steps v-if="hasOnboardingSteps" />

    <div class="px-2 px-md-3" v-if="!hasOnboardingSteps">
      <milestones />
    </div>

    <div class="px-2 px-md-3" v-if="hasFlashbackMediaItems">
      <row-button to="/terugblik">
        Terugblik {{ user.flashback_date }}
      </row-button>
    </div>

    <div class="px-2 px-md-3 pt-2 pb-2" v-if="highlightedSnacks.length">
      <media-item
        v-for="snack in highlightedSnacks"
        class="mb-3"
        :media-item="snack.mediaItem ? snack.mediaItem : null"
        :key="snack.id"
        :url="snack.translation.url"
        :title="snack.translation.title"
        :unread="true"
        :description="snack.translation.description"
        :highlighted="true"
      />
    </div>

    <div class="feed__articles p-2 p-md-3">
      <div v-if="activeSnacks && activeSnacks.length">
        <h2 class="feed__articles-heading">
          Nieuw voor jou
        </h2>

        <img
          v-if="theme.value === 'christmas'"
          src="/img/themes/christmas/twig.svg"
          alt="Kersttakje"
          class="feed-articles__decoration"
        >

        <media-item
          v-for="snack in nonHighlightedSnacks"
          class="mb-2 mb-md-3"
          :media-item="snack.mediaItem ? snack.mediaItem : null"
          :key="snack.id"
          :url="snack.translation.url"
          :title="snack.translation.title"
          :unread="true"
          :description="snack.translation.description"
        />
      </div>

      <div class="feed__articles-more pt-2 pt-md-0 pb-3">
        <div v-if="activeSnacks && activeSnacks.length">
          <img
            src="../assets/check-white.svg"
            width="44"
            alt=""
          />

          <p>
            {{ $textInterpolate.interpolate(`
              Je hebt het einde van de lijst bereikt! Ben je nog niet uitgelezen?
              Lees dan alle artikelen onder de zoekfunctie.
            `) }}
          </p>
        </div>

        <div v-else-if="activePet && activePet.days_since_birth > 365">
          <img
            src="../assets/check-white.svg"
            width="44"
            alt=""
          />

          <p>
            {{ $textInterpolate.interpolate(`
              Bedankt dat we dit jaar met jullie mee mochten groeien!
              Je [adult-type] is nu officieel geen [type] meer. De Kwispelcoach
              groeit met je huisdier mee tot de leeftijd van 1 jaar. Daarna krijg
              je geen nieuwe snacks meer van ons. Het natuurlijk wel altijd mogelijk
              om alles terug te lezen onder de zoekfunctie.
            `) }}
          </p>
        </div>

        <div v-else>
          <p>
            {{ $textInterpolate.interpolate(`
              Je hebt alle berichten van vandaag gelezen. Binnen een paar dagen ontvang
              je weer nieuwe snacks, dus houd je app in de gaten :) Ben je op zoek
              naar een bepaald onderwerp, gebruik dan de zoekfunctie.
            `) }}
          </p>
        </div>

        <Button
          component-type="router-link"
          :to="{ name: 'Search' }"
        >
          Bekijk alle artikelen
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FeedPetStage from '../components/FeedPetStage.vue';
import DashboardOnboardingSteps from '../components/DashboardOnboardingSteps.vue';
import Milestones from '../components/Milestones.vue';
import MediaItem from '../components/MediaItem.vue';
import Button from '../components/Button.vue';
import RowButton from '../components/RowButton.vue';

export default {
  inject: ['theme'],

  components: {
    Button,
    MediaItem,
    Milestones,
    DashboardOnboardingSteps,
    FeedPetStage,
    RowButton,
  },

  computed: {
    ...mapGetters([
      'user',
      'hasOnboardingSteps',
      'activeSnacks',
      'activePet',
    ]),

    nonHighlightedSnacks() {
      if (!this.activeSnacks) {
        return [];
      }

      return this.activeSnacks.filter((article) => !article.is_highlighted);
    },

    highlightedSnacks() {
      if (!this.activeSnacks) {
        return [];
      }

      return this.activeSnacks.filter((article) => article.is_highlighted);
    },

    hasFlashbackMediaItems() {
      if (!this.user) {
        return false;
      }

      return this.user.flashback_media_items && this.user.flashback_media_items.length;
    },
  },
};
</script>
