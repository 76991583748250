<template>
  <div class="feed-pet-stage p-2 p-md-3" v-if="activePetStage && activePetStageProgress !== null">
    <div class="feed-pet-stage__title">
      <span class="sr-only">Fase van je huisdier: </span> {{ activePetStage.translation.title }}
    </div>

    <div class="feed-pet-stage__progress">
      <div
        class="feed-pet-stage__progress-bar"
        :style="{ width: `${activePetStageProgress * 100}%` }"
      >
        <span class="sr-only">
          {{ Math.round(activePetStageProgress * 100) }} in huidige huisdier fase
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'user',
      'activePetStage',
      'activePetStageProgress',
    ]),
  },
};
</script>

<style>
.feed-pet-stage__title {
  color: var(--purple);
  font-weight: 700;
  line-height: 1.25;
}

.feed-pet-stage__progress,
.feed-pet-stage__progress-bar {
  height: .5rem;
  border-radius: .25rem;
}

.feed-pet-stage__progress {
  margin-top: .75rem;
  background-color: var(--purple);
}

.feed-pet-stage__progress-bar {
  background-color: var(--secondary);
}
</style>
