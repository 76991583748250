export default () => {
  let displayMode = 'browser';
  const mqStandAlone = '(display-mode: standalone)';

  if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
    displayMode = 'standalone';
  }

  return displayMode;
};
