<template>
  <app-layout classes="feed">
    <div class="feed__head">
      <feed-head />

      <tabs
        :active-tab="feedTab"
        :tabs="[
          { identifier: 'feed', title: 'Home' },
          { identifier: 'growth', title: 'Groeimeter' },
        ]"
        @change-tab="changeTab"
      />
    </div>

    <feed-list
      v-if="feedTab === 'feed'"
    />

    <feed-growth
      v-if="feedTab === 'growth'"
    />

    <standalone-popup />
  </app-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import AppLayout from './AppLayout.vue';
import FeedHead from '../components/FeedHead.vue';
import StandalonePopup from '../components/StandalonePopup.vue';
import Tabs from '../components/Tabs.vue';
import FeedList from './FeedList.vue';
import FeedGrowth from './FeedGrowth.vue';

export default {
  components: {
    FeedGrowth,
    FeedList,
    Tabs,
    StandalonePopup,
    FeedHead,
    AppLayout,
  },

  data() {
    return {
      feedTab: 'feed',
    };
  },

  computed: {
    ...mapGetters([
      'user',
    ]),
  },

  mounted() {
    this.$store.dispatch('fetchUser');
  },

  methods: {
    changeTab(identifier) {
      this.feedTab = identifier;
    },
  },
};
</script>

<style>
.app-layout.feed {
  display: flex;
  flex-direction: column;
}

.feed__head {
  background-color: #fff;
  padding-bottom: 1rem;
}

.feed__articles {
  background-color: var(--background-green-color);
  flex-grow: 1;
  position: relative;
}

.feed__articles-heading {
  font-weight: 500;
  color: #fff;
  margin-bottom: 1rem;
}

.feed__articles-more {
  color: #fff;
  text-align: center;
}

.feed-articles__decoration {
  top: -.5rem;
  right: 1rem;
  width: 4.875rem;
  position: absolute;
  transform: rotate(-24deg);
}

@media (min-width: 640px) {
  .feed__articles-heading {
    margin-bottom: 2rem;
  }
}
</style>
