<template>
  <div class="pl-2 pr-2 pl-md-3 pr-md-3">
    <row-button
      v-for="step in onboardingSteps"
      class="mb-2"
      :key="step.identifier"
      :to="step.url"
    >
      {{ step.title }}
    </row-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RowButton from './RowButton.vue';

export default {
  components: { RowButton },

  computed: {
    ...mapGetters([
      'onboardingSteps',
    ]),
  },
};
</script>
