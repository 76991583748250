<template>
  <div v-if="showStandalonePopup && deviceType">
    <div
      class="standalone-popup__backdrop"
      @click="onBackdropClick"
    ></div>
    <div
      class="standalone-popup"
    >
      <button
        class="standalone-popup__close"
        type="button"
        @click="onCloseClick"
      ><Icon identifier="close" /></button>

      <h2>
        Voeg Kwispelcoach toe aan je homescherm
      </h2>

      <div v-if="deviceType === 'ios'">
        <ol>
          <li>Klik onderaan op het deel icoontje.</li>
          <li>Klik op 'Zet in beginscherm'</li>
          <li>Klik op 'Toevoegen'</li>
        </ol>
      </div>

      <div v-if="deviceType === 'android'">
        <ol>
          <li>Klik rechtsbovenin op de drie puntjes.</li>
          <li>Klik op 'Toevoegen aan startscherm'</li>
          <li>Klik op 'Toevoegen'</li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
import browserMode from '../helpers/browserMode';
import Icon from './Icon.vue';

export default {
  components: {
    Icon,
  },

  data() {
    return {
      showStandalonePopup: false,
      deviceType: null,
    };
  },

  mounted() {
    if (/iPad|iPhone|iPod/.test(window.navigator.userAgent)) {
      this.deviceType = 'ios';
    } else if (/Android/.test(window.navigator.userAgent)) {
      this.deviceType = 'android';
    }

    this.checkPopupVisibility();
  },

  methods: {
    checkPopupVisibility() {
      if (browserMode() !== 'standalone' && window.localStorage) {
        const localStorageValue = window.localStorage.getItem('standalone-popup');

        if (!localStorageValue) {
          this.showStandalonePopup = true;
          return;
        }

        const timestamp = parseInt(localStorageValue, 10);

        if (Number.isNaN(timestamp)) {
          this.showStandalonePopup = true;
          return;
        }

        const timeDifference = (new Date()).getTime() - timestamp;
        const timeDifferenceInHours = timeDifference / 1000 / 60 / 60;

        // If not shown for a week...
        if (timeDifferenceInHours >= 168) {
          this.showStandalonePopup = true;
        }
      }
    },

    onBackdropClick() {
      this.markPopupShown();
    },

    onCloseClick() {
      this.markPopupShown();
    },

    markPopupShown() {
      this.showStandalonePopup = false;

      window.localStorage.setItem('standalone-popup', (new Date()).getTime());
    },
  },
};
</script>

<style>
.standalone-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: calc(100% - 2rem);
  max-height: calc(100% - 2rem);
  background-color: #fff;
  border-radius: 1rem;
  z-index: 3;
  padding: 1rem;
  width: 100%;
}

.standalone-popup__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 10, .5);
  z-index: 2;
}

.standalone-popup__close {
  position: absolute;
  top: -.5rem;
  right: -.5rem;
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid #ddd;
  padding: 0;
  background-color: #fff;
  border-radius: 50%;
  -webkit-appearance: none;
}

.standalone-popup__close svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: .8rem;
  height: .8rem;
}

.standalone-popup ol {
  counter-reset: list;
}

.standalone-popup ol li {
  counter-increment: list;
  position: relative;
  padding-left: 2rem;
  margin-bottom: .5rem;
}

.standalone-popup ol li:before {
  content: counter(list);
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--purple);
  color: #fff;
  border-radius: 50%;
  text-align: center;
}

@media (min-width: 480px) {
  .standalone-popup {
    width: 400px;
    padding: 2rem;
  }
}
</style>
