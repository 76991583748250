<template>
  <g
    :transform="`translate(${tooltipPosition.x}, ${tooltipPosition.y})`"
    v-if="active"
  >
    <rect
      :width="tooltipWidth"
      :height="tooltipHeight"
      fill="#7a2766"
      rx="6"
      ry="6"
    />

    <path
      d="M8.023,1a2.92,2.92,0,0,1,4.4,0l8.023,9.17H0Z"
      :transform="tooltipPosition.arrowTransform"
      fill="#7a2766"
    />

    <text
      x="43"
      y="23"
      text-anchor="middle"
      fill="#fff"
      font-size="12"
      font-weight="700"
    >
      {{ yLabel }}
    </text>

    <text
      x="43"
      y="41"
      text-anchor="middle"
      fill="#fff"
      font-size="12"
      font-weight="700"
    >
      {{ month }} maanden
    </text>
  </g>
</template>

<script>
export default {
  props: {
    circle: Object,
    active: Boolean,
    month: Number,
  },

  data() {
    return {
      tooltipWidth: 86,
      tooltipHeight: 56,
      spaceBetweenTooltip: 16,
    };
  },

  computed: {
    yLabel() {
      if (typeof this.circle.item.maxGrams !== 'undefined') {
        return `${this.circle.item.maxGrams} gram`;
      }

      if (typeof this.circle.item.maxCentimeters !== 'undefined') {
        return `${this.circle.item.maxCentimeters} cm`;
      }

      return '-';
    },

    tooltipPosition() {
      let x = this.circle.x - (this.tooltipWidth / 2);
      let y = this.circle.y - (this.tooltipHeight + this.spaceBetweenTooltip);
      let arrowTransform = 'translate(53, 63) rotate(180)';

      if (x < 0) {
        x = this.circle.x + this.spaceBetweenTooltip;
        y = this.circle.y - 14;
        arrowTransform = 'translate(-6, 25) rotate(-90)';
      } else if (y < 0) {
        y = this.circle.y + this.spaceBetweenTooltip;
        arrowTransform = 'translate(33, -6) rotate(0)';
      }

      return {
        x,
        y,
        arrowTransform,
      };
    },
  },
};
</script>
