<template>
  <div>
    <div class="p-2 p-md-3">
      <row-button
        class="mb-2"
        :to="{ name: 'PetWeight' }"
        icon="plus"
      >
        Gewicht toevoegen
      </row-button>

      <row-button
        :to="{ name: 'PetHeight' }"
        icon="plus"
      >
        Grootte toevoegen
      </row-button>
    </div>

    <div class="p-2 p-md-3 bg-white">
      <div class="mb-2">
        <div class="d-flex mb-3">
          <h2 class="mb-0 pr-2">
            Groeicurve
          </h2>

          <div class="ml-auto" v-if="activePet && activePet.race">
            {{ activePet.race }}
          </div>
        </div>

        <chart
          v-if="weight && weight.first"
          :statistic="weight"
          :statistic-labels="{
            first: 'Eerste weging',
            current: 'Huidig gewicht',
            previous: 'Vorige weging',
          }"
          formatter="weightFormatter"
          yVariableKey="maxGrams"
        />
      </div>

      <div class="mb-2">
        <chart
          v-if="height && height.first"
          :statistic="height"
          :statistic-labels="{
            first: 'Eerste meting',
            current: 'Huidige hoogte',
            previous: 'Vorige meting',
          }"
          formatter="heightFormatter"
          yVariableKey="maxCentimeters"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RowButton from '../components/RowButton.vue';
import Chart from '../components/Chart.vue';

export default {
  components: {
    Chart,
    RowButton,
  },

  computed: {
    ...mapGetters([
      'user',
      'weight',
      'height',
      'activePet',
    ]),
  },

  methods: {
    //
  },
};
</script>
