<template>
  <header class="feed-head p-2 p-md-3" v-if="user">
    <div
      v-if="
        user.active_pet
        && user.active_pet.avatar
        && user.active_pet.avatar.indexOf('avatar-pet') < 0
      "
      class="feed-head__avatar"
    >
      <img
        :src="user.active_pet.avatar"
        :alt="`${user.active_pet.name} avatar`"
        width="128"
        height="128"
      />

      <img
        v-if="theme.value === 'christmas'"
        src="/img/themes/christmas/twig.svg"
        alt="Kersttakje"
        class="feed-head__avatar-decoration"
      >
    </div>

    <div v-if="hasOnboardingSteps">
      <h1 class="feed-head__heading">
        Dag {{ userFirstName }}, maak je profiel compleet!
      </h1>

      <div>
        Krijg elke dag leuke tips!
      </div>
    </div>

    <div v-if="!hasOnboardingSteps">
      <h1 class="feed-head__heading">
        {{ salutation }} {{ userFirstName }}!
      </h1>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  inject: ['theme'],

  computed: {
    ...mapGetters([
      'user',
      'hasOnboardingSteps',
      'petName',
      'userFirstName',
    ]),

    salutation() {
      const date = new Date();
      const hour = date.getHours();

      if (hour >= 5 && hour < 12) {
        return 'Goedemorgen';
      }

      if (hour >= 12 && hour < 18) {
        return 'Goedemiddag';
      }

      return 'Goedenavond';
    },
  },
};
</script>

<style>
.feed-head {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.feed-head__avatar {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  flex: 0 0 4rem;
  margin-right: 1rem;
  position: relative;
}

.feed-head__avatar > img:not(.feed-head__avatar-decoration) {
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 50%;
}

.feed-head__avatar-decoration {
  left: -.5rem;
  bottom: -1rem;
  position: absolute;
}

.feed-head__heading {
  font-size: 1.0625rem;
  font-weight: 700;
  margin-bottom: .5rem;
}
</style>
