<template>
  <div>
    <div class="row mb-2" v-if="statistic">
      <div class="col" v-if="statistic.first">
        <div class="statistic__label">{{ statisticLabels.first }}</div>
        <div class="statistic__value">{{ handleFormatterCall(formatter, statistic.first) }}</div>
      </div>

      <div class="col" v-if="statistic.current">
        <div class="statistic__label">{{ statisticLabels.current }}</div>
        <div class="statistic__value">{{ handleFormatterCall(formatter, statistic.current) }}</div>
      </div>

      <div class="col" v-if="statistic.previous">
        <div class="statistic__label">{{ statisticLabels.previous }}</div>
        <div class="statistic__value">{{ handleFormatterCall(formatter, statistic.previous) }}</div>
      </div>
    </div>

    <div class="statistic__chart-wrap">
      <svg
        viewBox="0 0 540 200"
        width="540"
        height="200"
        v-if="statistic.chartData"
        @click="onChartClick"
      >
        <polyline
          fill="none"
          stroke="#7a2766"
          stroke-width="4"
          v-bind:points="pointsAsPolyline"
        />

        <!-- White ring around active dot -->
        <circle
          v-for="circle in pointsAsPositions"
          :key="circle.x"
          :fill="circle.index === activeIndex ? '#fff' : 'transparent'"
          :cx="circle.x"
          :cy="circle.y"
          :r="12"
          style="filter: drop-shadow(1px 2px 4px rgba(0, 0, 0, .3))"
        />

        <!-- Dot -->
        <circle
          v-for="circle in pointsAsPositions"
          :key="circle.x"
          :fill="circle.index === activeIndex ? '#f06b00' : '#7a2766'"
          :cx="circle.x"
          :cy="circle.y"
          :r="circle.index === activeIndex ? 7 : 5"
        />

        <!-- Hit target -->
        <circle
          v-for="circle in pointsAsPositions"
          :key="circle.x"
          fill="transparent"
          :cx="circle.x"
          :cy="circle.y"
          r="18"
          @click="(event) => onDotClick(event, circle)"
        />

        <chart-tooltip
          v-for="circle in pointsAsPositions"
          :key="circle.x"
          :circle="circle"
          :active="circle.index === activeIndex"
          :month="circle.index"
        />
      </svg>

      <div class="statistic__chart-x d-flex">
        <div v-for="index in 12" :key="index">
          {{ index - 1 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartTooltip from './ChartTooltip.vue';

export default {
  components: {
    ChartTooltip,
  },

  props: {
    statistic: {
      type: Object,
    },

    statisticLabels: {
      type: Object,
    },

    formatter: {
      type: String,
      default: 'defaultFormatter',
    },

    yVariableKey: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      activeIndex: null,
    };
  },

  computed: {
    pointsAsPositions() {
      if (!this.statistic) {
        return [];
      }

      return this.pointsToPositions(this.statistic.chartData, 520, 200);
    },

    pointsAsPolyline() {
      return this.pointsAsPositions.map((position) => (
        `${position.x} ${position.y}`
      )).join(' ');
    },
  },

  methods: {
    pointsToPositions(points, width, height) {
      const spaceBetweenPoints = width / points.length;

      const positions = [];

      // Get the minimum value of the dataset...
      let min = points.reduce((acc, item) => {
        return item && item[this.yVariableKey] < acc ? item[this.yVariableKey] : acc;
      }, Infinity);

      // Get the maximum value of the dataset...
      let max = points.reduce((acc, item) => {
        return item && item[this.yVariableKey] > acc ? item[this.yVariableKey] : acc;
      }, 0);

      // Get the distance between the minimum and maximum values...
      let range = max - min;

      // When we've a single point on the chart the range will be 0. We don't
      // want that to happen because we calculate using the range and dividing
      // by zero does not give the correct results...
      if (min === max) {
        min -= 10;
        max += 10;
        range = 20;
      }

      points.forEach((item, index) => {
        if (!item) {
          return;
        }

        // Calculate the percentage position on the y-axis. So 0.5 puts
        // the position in the center of the chart (vertically)
        const yPercentage = (item[this.yVariableKey] - min) / range;

        // Transform the y-axis percentage into a pixel value on the chart.
        // yPercentage = 0.5 on a 200px tall chart will put it a 100px.
        const y = height - (yPercentage * height);

        positions.push({
          index,
          item,
          x: (index * spaceBetweenPoints),
          y,
        });
      });

      return positions;
    },

    handleFormatterCall(formatter, value) {
      return this[formatter](value);
    },

    defaultFormatter(value) {
      return value;
    },

    weightFormatter(weight) {
      if (Number.isNaN(weight)) {
        return '';
      }

      if (weight < 1000) {
        return `${weight} gram`;
      }

      return `${(weight / 1000).toFixed(1)} Kg`;
    },

    heightFormatter(height) {
      if (Number.isNaN(height)) {
        return '';
      }

      if (height < 100) {
        return `${height} Cm`;
      }

      return `${(height / 100).toFixed(1)} m`;
    },

    onDotClick(event, item) {
      event.stopPropagation();

      this.activeIndex = item.index;
    },

    onChartClick() {
      this.activeIndex = null;
    },
  },
};
</script>

<style scoped>
.statistic__label {
  color: #887e76;
}

.statistic__value {
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--purple);
}

.statistic__chart-wrap {
  overflow-x: auto;
  width: 100%;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.statistic__chart-x {
  width: 520px;
  margin-left: -3px;
}

.statistic__chart-x > div {
  flex: 1 1 0px;
}

.statistic__chart-wrap svg {
  overflow: visible;
  margin-bottom: 1rem;
}

@media (min-width: 600px) {
  .statistic__chart-wrap svg {
    width: 100%;
    height: auto;
  }

  .statistic__chart-x {
    width: calc(100% - 1.75rem);
  }
}
</style>
